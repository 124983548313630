import isEmail from "validator/es/lib/isEmail";
const router = {
  path: "/",
  component: () => import("../views/GuestApp.vue"),
  children: [
    {
      path: "/signup",
      name: "signup",
      component: () => import("../views/guest/signup.vue"),
      meta: {
        guest: true
      }
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../views/guest/login.vue"),
      meta: {
        guest: true
      },
      props: route => {
        const token = route.query.token;
        const email = route.query.email;
        return {
          tokenVerif: token,
          emailVerif: email && isEmail(email) ? email : null
        };
      }
    },
    {
      path: "/olvido-contrasena",
      name: "olvido-contrasena",
      component: () => import("../views/guest/olvido-contrasena.vue"),
      meta: {
        guest: true
      }
    },
    {
      path: "/forget-password",
      name: "forget-password",
      component: () => import("../views/guest/forget-password.vue"),
      meta: {
        guest: true
      }
    },
    {
      path: "/logout-success",
      name: "logout-success",
      component: () => import("../views/guest/logout.vue"),
      meta: {
        guest: true
      }
    }
  ]
};
export default router;
