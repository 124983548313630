const router = {
  path: "/",
  component: () => import("../views/persona/PersonaApp.vue"),
  children: [
    {
      path: "datos",
      name: "persona-datos",
      component: () => import("../views/persona/EditDatos.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "sugerencias",
      name: "persona-sugerencia",
      component: () => import("../views/persona/Sugerencia.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "informes",
      name: "informes",
      component: () => import("../views/persona/Informes.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "informes/areaSembrada",
      name: "informes-area-sembrada",
      component: () =>
        import("../views/persona/informes/InformeAreaSembrada.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "informes/plagas",
      name: "informes-plagas",
      component: () => import("../views/persona/informes/InformePlagas.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "informes/sustentabilidad",
      name: "informes-sustentabilidad",
      component: () => import("../views/persona/informes/InformesSustentabilidad.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "lotes",
      name: "persona-lotes",
      component: () => import("../views/persona/Lotes.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "lotes/equivalencias",
      name: "persona-lotes-equivalencias",
      component: () => import("../views/persona/LotesEquivalencias.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "establecimientos",
      name: "persona-establecimientos",
      component: () => import("../views/persona/Establecimientos.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "lluvia/:idEstablecimiento",
      name: "establecimiento-lluvia",
      component: () => import("../views/persona/Lluvia.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "mapa",
      name: "persona-mapa",
      component: () => import("../views/persona/Mapa.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "lotes/:tab/:idLote",
      name: "lote",
      component: () => import("../views/persona/FichaLote.vue"),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "general",
          name: "lote-general",
          component: () => import("../components/FichaGeneralCampCultivo.vue")
        },
        {
          path: "actividades",
          name: "lote-actividades",
          component: () => import("../components/FichaActividades.vue")
        }
      ]
    },
    {
      path: "lotes/:tab/:idLote/campanias",
      name: "lote-campanias",
      component: () => import("../views/persona/Campanias.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "lotes/:idLote/createActividad/:tipoActividad/:fecha",
      name: "createActividad",
      component: () => import("../views/persona/Actividad.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "lotes/:idLote/editActividad/:idActividad",
      name: "editActividad",
      component: () => import("../views/persona/Actividad.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "lotes/:idLote/createAdversidad",
      name: "createAdversidad",
      component: () => import("../views/persona/Adversidad.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "lotes/:idLote/editAdversidad/:idAdversidad",
      name: "editAdversidad",
      component: () => import("../views/persona/Adversidad.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "password",
      name: "password",
      component: () => import("../views/persona/EditContraseña.vue"),
      meta: {
        requiresAuth: true
      }
    }
  ]
};

export default router;
