const router = {
  path: "/",
  component: () => import("../views/persona/PersonaApp.vue"),
  children: [
    {
      path: "productores",
      name: "admin-productores",
      component: () => import("../views/persona/admin/Productores.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: true
      }
    },
    {
      path: "/productores/:idProductor/lotes",
      name: "admin-lotes-productor",
      component: () => import("../views/persona/Lotes.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: true
      }
    },
    {
      path: "/productores/:idProductor/establecimientos",
      name: "admin-establecimientos-productor",
      component: () => import("../views/persona/Establecimientos.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: true
      }
    },
    /*{
      path: "/productores/:idProductor/mapa",
      name: "admin-mapa-productor",
      component: () => import("../views/persona/Mapa.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: true
      }
    },*/
    {
      path: "/productores/:idProductor/lotes/:tab/:idLote",
      name: "admin-productor-lote",
      component: () => import("../views/persona/FichaLote.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: true
      }
    },
    {
      path: "/productores/:idProductor/lotes/:tab/:idLote/campanias",
      name: "admin-campanias-lote",
      component: () => import("../views/persona/Campanias.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: true
      }
    },
    {
      path:
        "/productores/:idProductor/lotes/:idLote/createActividad/:tipoActividad/:fecha",
      name: "admin-productor-createActividad",
      component: () => import("../views/persona/Actividad.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: true
      }
    },
    {
      path:
        "/productores/:idProductor/lotes/:idLote/editActividad/:idActividad",
      name: "admin-productor-editActividad",
      component: () => import("../views/persona/Actividad.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: true
      }
    },
    {
      path: "/productores/:idProductor/lotes/:idLote/createAdversidad",
      name: "admin-productor-createAdversidad",
      component: () => import("../views/persona/Adversidad.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: true
      }
    },
    {
      path:
        "/productores/:idProductor/lotes/:idLote/editAdversidad/:idAdversidad",
      name: "admin-productor-editAdversidad",
      component: () => import("../views/persona/Adversidad.vue"),
      meta: {
        requiresAuth: true,
        requiresAdmin: true
      }
    }
  ]
};

export default router;
