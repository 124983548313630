<template>
  <ion-footer :translucent="false" color="primary">
    <ion-toolbar color="primary">
      <ion-row>
        <ion-col class="margen8pxArriba">
          <ion-text id="version" color="light" class="leftMargin2vh" style="font-size:small">
            Aapresid - v{{ _version }} - BETA
          </ion-text>
        </ion-col>
        <ion-col size="auto">
          <ion-button fill="clear" size="small" @click="goToApreesid">
            <ion-img src="assets/icon/AAP_Logo_blanco.png" style="height:100%" />
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import { version } from "../../package.json";
import { IonFooter, IonToolbar, IonText, IonRow, IonCol, IonButton, IonImg } from "@ionic/vue";
export default {
  components: {
    IonFooter,
    IonToolbar,
    IonText,
    IonRow,
    IonCol,
    IonButton,
    IonImg
  },
  computed: {
    _version() {
      return version;
    }
  },

  methods: {
    goToApreesid() {
      window.open("https://www.aapresid.org.ar/");
    }
  }
};
</script>
<style>
.margen8pxArriba {
  margin-top: 8px;
}
</style>
