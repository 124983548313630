import {
  createRouter,
  createWebHistory
  //createWebHashHistory
} from "@ionic/vue-router";
import guestRoutes from "./guest";
import personaRoutes from "./persona";
import adminRoutes from "./admin";
import store from "./../store";

const routes = [
  {
    path: "",
    name: "home",
    beforeEnter: async (to, from, next) => {
      if (store.getters.isLoggedIn) {
        if (!store.getters["user"]?._id) {
          await store.dispatch("getMe");
        }
        await store.dispatch("getUserAdmin");
        if (store.getters.isAdmin) {
          return next({ name: "admin-productores" });
        }
        return next({ name: "persona-lotes" });
      } else {
        return next({ name: "login" });
      }
    }
  },
  {
    path: "/logout",
    name: "logout",
    beforeEnter: async (to, from, next) => {
      await store.dispatch("logout");
      return next({ name: "logout-success" });
    }
  },
  guestRoutes,
  personaRoutes,
  adminRoutes
];

const router = createRouter({
  /*mode: process.env.NODE_ENV === "production" ? "history" : "hash",
  history:
    process.env.NODE_ENV === "production"
      ? createWebHistory(process.env.BASE_URL)
      : createWebHashHistory(process.env.BASE_URL),*/
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, _, next) => {
  window.scrollTo(0, 0);
  if (to.matched.some(record => record.meta.guest)) {
    if (store.getters.isLoggedIn) {
      if (
        to.query.tkn &&
        to.name === "login" &&
        (!to.query.ro || to.query.ro === false)
      ) {
        await store.dispatch("logout");
        next({ name: "login", query: { tkn: to.query.tkn, ro: true } });
      } else {
        next({ name: "home" });
      }
    } else {
      if (
        to.query.tkn &&
        to.name === "login" &&
        (!to.query.ro || to.query.ro === false)
      ) {
        next({ name: "login", query: { tkn: to.query.tkn, ro: true } });
      } else {
        next();
      }
    }
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      if (
        to.query.tkn &&
        to.name === "login" &&
        (!to.query.ro || to.query.ro === false)
      ) {
        await store.dispatch("logout");
        next({ name: "login", query: { tkn: to.query.tkn, ro: true } });
      } else {
        // Usuario con token guardado pero recien entra ala app
        if (store.getters.isLoggedIn && !store.getters.user?._id) {
          const { data: user, error } = await store.dispatch("getMe", null, {
            root: true
          });
          if (error || !user) {
            await store.dispatch("logout");
            next({ name: "home" });
          }
          if (to.matched.some(record => record.meta.requiresAdmin)) {
            await store.dispatch("getUserAdmin", null, { root: true });
            if (!store.getters.isAdmin) {
              next({ name: "home" });
            }
          }
        }
        next();
      }
    } else {
      // Require perminos y usuario no loqueado
      if (
        to.query.tkn &&
        to.name === "login" &&
        (!to.query.ro || to.query.ro === false)
      ) {
        next({ name: "login", query: { tkn: to.query.tkn, ro: true } });
      } else {
        next({ name: "login" });
      }
    }
  } else {
    next();
  }
});

export default router;
