<template translate="no">
  <IonApp>
    <Header
      v-if="showHeaderAndFooter || showOnlyHeader"
      style="top: 0px; bottom:0;"
    />
    <router-view :key="$route.fullPath" />
    <Footer v-if="showHeaderAndFooter" style="top: 0px; bottom:0;" />
  </IonApp>
</template>

<script>
import { IonApp } from "@ionic/vue";
import Header from "./components/Header.vue";
import Footer from "./components/Footer";
export default {
  components: { IonApp, Header, Footer },
  computed: {
    showHeaderAndFooter() {
      const routes = [
        "forget-password",
        "signup",
        "persona-mapa",
        "login",
        "logout-success",
        "olvido-contrasena"
      ];
      return routes.indexOf(this.$route.name) === -1;
    },
    showOnlyHeader() {
      const routes = ["persona-mapa"];
      return routes.indexOf(this.$route.name) !== -1;
    }
  }
};
</script>

<style></style>
