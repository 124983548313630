<template>
  <ion-header :translucent="false" color="primary">
    <ion-toolbar color="primary">
      <ion-buttons slot="start">
        <ion-menu-button color="light"></ion-menu-button>
      </ion-buttons>
      <ion-row style="padding: 0px">
        <ion-col size="auto" style="padding: 0px">
          <ion-button
            fill="clear"
            size="large"
            style="margin: 0px; --padding-start: 0px"
            @click="goToHome"
          >
            <ion-img src="assets/icon/AAP_Logo_blanco.png" style="height:90%" />
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  //IonThumbnail,
  IonButton,
  IonImg
} from "@ionic/vue";
export default {
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonRow,
    IonCol,
    //IonThumbnail,
    IonButton,
    IonImg
  },
  methods: {
    goToHome() {
      this.$router.push("/");
    }
  }
};
</script>
