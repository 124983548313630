import { createStore } from "vuex";
import http from "../api/http";
import { Usuario } from "../api";
import Token from "../api/Token";

export default createStore({
  strict: process.env.NODE_ENV !== "production",
  state: {
    user: {},
    admin_user: {},
    dataPasada: {},
    token: localStorage.getItem("_t") || null
  },
  getters: {
    isLoggedIn: state => !!state.user._id || !!state.token,
    isAdmin: state => state.admin_user && state.admin_user._id,
    isAdminRegional: state =>
      state.admin_user && state.admin_user.roles.includes("ADMIN_REGIONAL"),
    user: state => state.user
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_ADMIN_USER(state, admin_user) {
      state.admin_user = admin_user;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_DATA_PASADA(state, dataNueva) {
      state.dataPasada = dataNueva;
    }
  },
  actions: {
    async login({ commit }, { email, password }) {
      try {
        const { data } = await http.post("/api/persona_auth/login", {
          email,
          password
        });
        if (data && data.email_verified) {
          commit("SET_USER", data);
        }
        return { data };
      } catch (resp) {
        return {
          error:
            resp.status === 401 ? "Contraseña o email no valido." : resp.error
        };
      }
    },

    async loginAapresid({ commit }, token) {
      try {
        const { data } = await http.post(
          "/api/persona_auth/logInAapresidUser",
          {
            token: token
          }
        );
        if (data && data.email_verified) {
          commit("SET_USER", data);
        }
        return { data };
      } catch (resp) {
        if (resp.status === 404) {
          return {
            error: "Usuario no encontrado."
          };
        }
        if (resp.status === 401) {
          return {
            error: "Tóken Inválido."
          };
        }
        return resp.error;
      }
    },

    async signup(_, { user, wPassword }) {
      try {
        return await http.post("/api/persona_auth/signup", { user, wPassword });
      } catch (rta) {
        return rta;
      }
    },

    async logout({ commit }) {
      try {
        const rta = await http.post("/api/persona_auth/logout");
        Token.deleteAll();
        commit("SET_USER", {});
        commit("SET_ADMIN_USER", {});
        commit("SET_TOKEN", null);
        return rta;
      } catch (rta) {
        return rta;
      }
    },
    async sendEmail(_, { email }) {
      try {
        return await http.post("/api/persona_auth/sendemail", { email });
      } catch (rta) {
        return rta;
      }
    },
    async signupVerification(_, { email, token }) {
      try {
        return await http.post("/api/persona_auth/signup/verification", {
          email,
          token
        });
      } catch (rta) {
        return rta;
      }
    },
    async forgetPassword(_, { email }) {
      try {
        return await http.post("/api/persona_auth/forgetpassword", { email });
      } catch (rta) {
        return rta;
      }
    },
    async changeForgetPassword(_, { password, token }) {
      try {
        return await http.post("/api/persona_auth/forgetpassword/change", {
          token,
          password
        });
      } catch (rta) {
        return rta;
      }
    },
    async changePassword(_, { actualPassword, newPassword }) {
      try {
        const { data, error, status } = await http.post(
          "/api/persona_auth/changeOwnPassword",
          {
            actualPassword,
            newPassword
          }
        );
        if (!error) {
          return { data: data.data, error: null };
        }
        return { data: null, error, status };
      } catch (rta) {
        return rta;
      }
    },
    async getMe({ commit }) {
      try {
        const { data, error } = await http.get("/api/persona_auth/me");
        commit("SET_USER", data);
        return { data, error };
      } catch (error) {
        return error;
      }
    },
    async getUserAdmin({ commit, state }) {
      try {
        const { data, error } = await Usuario.get({
          query: { email: state.user.email, estado: "HABILITADO" },
          populate: "regional"
        });
        commit("SET_ADMIN_USER", data[0]);
        return { data: data[0], error };
      } catch (error) {
        return error;
      }
    },
    async getAapresidUser(_, token) {
      try {
        const { data, error } = await http.get(
          "/api/persona_auth/getAapresidUser",
          {
            params: {
              token: token
            }
          }
        );
        return { data, error };
      } catch (error) {
        return error;
      }
    },
    async checkRecoveryToken(_, token) {
      try {
        const { data } = await http.get(
          "/api/persona_auth/forgetpassword/valid",
          {
            params: { token }
          }
        );
        return { data: data.data, error: null };
      } catch (rta) {
        return rta;
      }
    },
    mandarData({commit}, dataNueva) {
      commit("SET_DATA_PASADA", dataNueva);
    }
  },
  modules: {}
});
