import baseApi from "./baseApi";
export const Persona = baseApi("/persona");
export const Usuario = baseApi("/usuarios");
export const Lotes = baseApi("/lote");
export const Regionales = baseApi("/regional");
export const Campanias = baseApi("/campania");
export const CampaniaCultivos = baseApi("/campaniaCultivo");
export const Actividades = baseApi("/actividad");
export const Productos = baseApi("/producto");
export const ProductosFertilizacion = baseApi("/productoFertilizacion");
export const Cultivos = baseApi("/cultivo");
export const Semilleros = baseApi("/semillero");
export const Agua = baseApi("/agua");
export const Suelo = baseApi("/suelo");
export const Rinde = baseApi("/rinde");
export const Adversidades = baseApi("/adversidad");
export const TiposAdversidades = baseApi("/tipoAdversidad");
export const VariedadesCultivo = baseApi("/variedadCultivo");
export const PlagasPulverizacion = baseApi("/plagaPulverizacion");
export const ProductosPlaga = baseApi("/productoPlaga");
export const Malezas = baseApi("/maleza");
export const Insectos = baseApi("/insecto");
export const Enfermedades = baseApi("/enfermedad");
export const Lluvias = baseApi("/lluvia");
export const Localidades = baseApi("/localidad");
export const Provincias = baseApi("/provincia");
export const Establecimientos = baseApi("/establecimiento");

export default baseApi;
